<template>
  <n-modal :is-open="true" title="Einbuchen" @close="close">
    <div class="h-full py-7 px-6 flex justify-between flex-col">
      <div class="flex flex-col justify-start mb-10">
        <div class="flex justify-start">
          <span class="font-bold mr-3 text-left">{{ curriculumModule.moduleTitle }}</span>
          <module-chip />
        </div>
        <span class="flex text-xs text-gray-700 mb-2"
          >Version: {{ curriculumModule.moduleDescriptionVersionCode }}</span
        >
        <n-select
          id="Modulangebot"
          data-test="enrolment-modal__module-offer-field"
          :model-value="!!currentModuleEnrolment ? curriculumModule : selectedModuleOfferId"
          :disabled="!!currentModuleEnrolment"
          label="Modulangebot"
          required
          :options="moduleOffers"
          option-label="name"
          :errors="errors"
          track-by="id"
          searchable
          class="text-left"
          @update:model-value="selectModuleOffer"
        ></n-select>
      </div>
      <div
        v-for="(curriculumCourse, id) in curriculumModule.curriculumCourses"
        :key="id"
        class="flex justify-start flex-col mb-10"
      >
        <div class="flex justify-start">
          <span class="mr-3 text-left">{{ curriculumCourse.courseTitle }}</span>
          <course-chip />
        </div>
        <span class="flex text-xs break-all text-gray-700 mb-2"
          >Version: {{ curriculumCourseVersion(curriculumCourse) }}</span
        >
        <n-select
          :id="`courseTextfield-${id}`"
          label="Vorlesungsreihe"
          searchable
          option-label="name"
          class="text-left"
          :disabled="!!curriculumCourseEnrolment(curriculumCourse)"
          :options="courseOffers[curriculumCourse.id] || []"
          track-by="id"
          :model-value="getEnroledOrSelectedCourseOfferId(id, curriculumCourse)"
          @update:model-value="addCourseOffer(id, curriculumCourse.id, $event)"
        ></n-select>
      </div>
      <div class="flex justify-start mb-4">
        <span class="text-sm text-gray-700">*Pflichtfelder</span>
      </div>
      <div>
        <n-banner
          v-if="failedEnrolments.length"
          data-test="enrolment-modal__error-banner"
          class="h-full items-start"
          variant="error"
        >
          <template #message>
            <div v-if="failedEnrolments.length === 1" class="text-left py-2">
              <span>{{ failedEnrolments[0].message }}</span>
            </div>
            <div v-else class="flex flex-col text-left py-2">
              <span>Einbuchung in folgende Angebote ist fehlgeschlagen:</span>
              <ul class="list-disc list-inside">
                <li v-for="enrolment in failedEnrolments" :key="enrolment.id">
                  {{ enrolment.message }}
                </li>
              </ul>
            </div>
          </template>
          <template #actions
            ><n-button variant="quaternary" @click="failedEnrolments = []"
              ><span class="text-white hover:text-gray-100 uppercase"
                ><n-icon icon="mdi-close"></n-icon></span
            ></n-button>
          </template>
        </n-banner>
      </div>
    </div>
    <template #actions>
      <n-button
        data-test="enrolment-modal__enrolment-button"
        :disabled="(!selectedCourseOffers.length && !selectedModuleOffer) || loading"
        @click="validateAndCreateEnrolment"
        >{{ loading ? 'Wird gebucht...' : 'Einbuchen' }}</n-button
      >
    </template>
  </n-modal>
</template>

<script>
import { NBanner, NButton, NIcon, NModal, NSelect } from '@careerpartner/nitro';
import CourseChip from '@/components/common/CourseChip.vue';
import ModuleChip from '@/components/common/ModuleChip.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapErrorMessage, VALIDATION_ERROR_MAP } from '@/common/errors';

export default {
  name: 'EnrolmentModal',
  components: {
    CourseChip,
    ModuleChip,
    NModal,
    NSelect,
    NButton,
    NBanner,
    NIcon,
  },

  props: {
    curriculumModule: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedCourseOffers: [],
      selectedModuleOffer: undefined,
      selectedModuleOfferId: undefined,
      errors: undefined,
      loading: false,
      successfulEnrolments: [],
      totalEcts: 0,
    };
  },

  computed: {
    ...mapGetters({
      getSelectedStudyProgress: `studyProgresses/getSelectedStudyProgress`,
      curriculumCourseVersion: `studyProgresses/curriculumCourseVersion`,
      getFailedEnrolments: 'overview/getFailedEnrolments',
      isEnrolmentOpen: 'overview/isEnrolmentOpen',
      moduleOffers: `offers/getModuleOffers`,
      courseOffers: `offers/getCourseOffers`,
    }),

    failedEnrolments: {
      get() {
        return this.getFailedEnrolments;
      },
      set(value) {
        return this.setFailedEnrolments(value);
      },
    },

    currentModuleEnrolment() {
      return this.curriculumModule.moduleProgress?.moduleEnrolment;
    },

    moduleEnrolment() {
      return {
        studyProgressId: this.getSelectedStudyProgress?.id,
        moduleOfferId: this.selectedModuleOffer?.id,
        curriculumModuleId: this.curriculumModule.id,
      };
    },

    courseEnrolments() {
      return this.selectedCourseOffers.map((selectedCourse) => {
        const curriculumCourse = this.curriculumModule.curriculumCourses.find(
          (curriculumCourse) => selectedCourse.offer.curriculumCourseId === curriculumCourse.id
        );

        return {
          studyProgressId: this.getSelectedStudyProgress?.id,
          courseOfferId: selectedCourse?.offer.id,
          curriculumCourseId: selectedCourse?.offer.curriculumCourseId,
          offer: selectedCourse.offer,
          ects: curriculumCourse.ects,
        };
      });
    },
  },

  beforeMount() {
    this.getOffers();
  },

  methods: {
    ...mapMutations({
      resetOffers: 'offers/reset',
      setSnackbarMessage: 'app/setSnackbarMessage',
      setFailedEnrolments: 'overview/setFailedEnrolments',
      closeAllModals: 'overview/closeAllModals',
    }),

    ...mapActions({
      fetchDataForSelectedStudyProgress: 'studyProgresses/fetchDataForSelectedStudyProgress',
      fetchModuleOffers: 'offers/fetchModuleOffers',
      fetchCourseOffers: 'offers/fetchCourseOffers',
      createModuleEnrolment: 'enrolments/createModuleEnrolment',
      createCourseEnrolment: 'enrolments/createCourseEnrolment',
    }),

    close() {
      this.resetOffers();
      this.closeAllModals();
    },

    curriculumCourseEnrolment(curriculumCourse) {
      return curriculumCourse.courseProgress?.courseEnrolment;
    },
    selectModuleOffer(moduleOfferId) {
      this.selectedModuleOfferId = moduleOfferId;
      this.selectedModuleOffer = this.moduleOffers.find((offer) => offer.id === moduleOfferId);
    },
    getEnroledOrSelectedCourseOfferId(elementId, curriculumCourse) {
      if (this.curriculumCourseEnrolment(curriculumCourse)) {
        return curriculumCourse;
      }
      const selectedCourseOffer = this.selectedCourseOffers.find(
        (selectedCourseOffer) => selectedCourseOffer.elementId === elementId
      );
      if (selectedCourseOffer) {
        return selectedCourseOffer.offer.id;
      }
    },

    addCourseOffer(elementId, curriculumCourseId, offerId) {
      // Remove offer if we get undefined payload from the event
      if (!offerId) {
        this.selectedCourseOffers = this.selectedCourseOffers.filter(
          (selectedOffer) => selectedOffer.elementId !== elementId
        );
      } else {
        // Use elementId (index of element) to map offer to right multiselect
        const course = this.courseOffers[curriculumCourseId].find(
          (course) => course.id === offerId
        );
        this.selectedCourseOffers.push({ offer: course, elementId });
      }
    },

    generateListing(enrolments) {
      return enrolments.reduce((acc, enrolment, id) => {
        const isLast = id === enrolments.length - 1 && enrolments.length !== 1;
        const isBeforeSecondtoLast = id < enrolments.length - 2;
        const name = `"${enrolment.name}"`;
        return `${acc}${isLast ? ' und ' : ''}${name}${isBeforeSecondtoLast ? ', ' : ''}`;
      }, '');
    },

    async handleSuccessfulEnrolment(successfulEnrolments) {
      const message = `Einbuchung in ${this.generateListing(
        successfulEnrolments
      )} war erfolgreich.`;
      await this.fetchDataForSelectedStudyProgress(this.getSelectedStudyProgress?.id);
      this.setSnackbarMessage(message);
    },

    async processModuleEnrolment() {
      const moduleOffer = { ...this.selectedModuleOffer };
      try {
        await this.createModuleEnrolment(this.moduleEnrolment);
        this.successfulEnrolments.push(moduleOffer);
      } catch (error) {
        this.failedEnrolments = [
          {
            ...moduleOffer,
            message: mapErrorMessage(error, moduleOffer.name),
          },
        ];
      }
    },

    async processCourseEnrolments() {
      const results = await Promise.allSettled(
        this.courseEnrolments.map(this.createCourseEnrolment)
      );
      results.forEach((result, index) => {
        const { offer, ects } = this.courseEnrolments[index];
        if (result.status === 'fulfilled') {
          this.totalEcts += ects;
          this.successfulEnrolments.push(offer);
        } else if (result.status === 'rejected') {
          this.failedEnrolments.push({
            ...offer,
            message: mapErrorMessage(result.reason, offer.name),
          });
        }
      });
    },

    async validateAndCreateEnrolment() {
      this.errors = [];
      this.successfulEnrolments = [];
      this.failedEnrolments = [];
      this.totalEcts = 0;

      if (!this.selectedModuleOffer && !this.currentModuleEnrolment) {
        this.errors = [VALIDATION_ERROR_MAP.ENROLMENT_VALIDATION_FAILED];
      } else {
        this.loading = true;

        if (this.selectedModuleOffer && !this.currentModuleEnrolment) {
          await this.processModuleEnrolment();
        }
        if (this.selectedCourseOffers.length && this.failedEnrolments.length === 0) {
          await this.processCourseEnrolments();
        }

        if (!this.failedEnrolments.length) {
          this.close();
        }
        if (this.successfulEnrolments.length) {
          await this.handleSuccessfulEnrolment(this.successfulEnrolments);
        }
        this.loading = false;
      }
    },

    async getOffers() {
      await Promise.all([
        this.fetchModuleOffers({
          curriculumModuleId: this.curriculumModule?.id,
          moduleMainVersionId: this.curriculumModule.moduleMainVersionId,
        }),
        ...this.curriculumModule.curriculumCourses.map((curriculumCourse) =>
          this.fetchCourseOffers({
            curriculumCourseId: curriculumCourse.id,
            courseMainVersionId: curriculumCourse.courseMainVersionId,
          })
        ),
      ]);

      // preselect module offer, if there is only one
      if (this.moduleOffers && this.moduleOffers.length === 1) {
        this.selectedModuleOffer = this.moduleOffers[0];
      }
    },
  },
};
</script>
