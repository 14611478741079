import { END_BOOKING_EVENTS } from '@/common/events/endBookingEvents';
import Store from '@/store';
import { Getters as BookingGetters } from '@/store/modules/booking/types';
import dayjs from 'dayjs';
import { DATE_FORMAT, ENROLMENT_STATUS_STRINGS, FEATURES } from '@/common/constants';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { CANCEL_REASON_KEYS } from '@/common/selectLists/cancellationReasons';
import { isActive } from '@/utils/getEnvAndFeatures';

const onCancellation = {
  id: END_BOOKING_EVENTS.ON_CANCELLATION,
  text: 'Vertrag kündigen',
  disabled: true,
  event: 'menu-button-clicked',
};

const onRecallCancellation = {
  id: END_BOOKING_EVENTS.ON_RECALL_CANCELLATION,
  text: 'Kündigung zurückziehen',
  disabled: true,
  event: 'menu-button-clicked',
  showBottomSeparator: true,
};

const onRevocation = {
  id: END_BOOKING_EVENTS.ON_REVOCATION,
  text: 'Vertrag widerrufen',
  event: 'menu-button-clicked',
  disabled: true,
  showBottomSeparator: false,
};

const onRecallRevocation = {
  id: END_BOOKING_EVENTS.ON_RECALL_REVOCATION,
  text: 'Widerruf zurückziehen',
  event: 'menu-button-clicked',
  disabled: true,
  showBottomSeparator: true,
};

const onGraduation = {
  id: END_BOOKING_EVENTS.ON_GRADUATION,
  event: 'menu-button-clicked',
  text: 'Graduierung',
  disabled: true,
  showBottomSeparator: false,
};

export default function (testBooking, testDay) {
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);

  const endBookingMenuItems = [
    onCancellation,
    onRecallCancellation,
    onRevocation,
    onRecallRevocation,
    onGraduation,
  ];

  const activatedItems = [];

  endBookingMenuItems.forEach((item) => (item.disabled = true));

  const currentBooking =
    testBooking || Store.getters[`booking/${BookingGetters.GET_CURRENT_BOOKING}`];

  const endDate = currentBooking?.endDate;

  const hasBeenCancelled =
    currentBooking.cancellationDetails &&
    Object.keys(currentBooking?.cancellationDetails).length !== 0;

  const isGraduated = currentBooking.graduationDetails?.exmatriculationDate;

  const hasNormalCancellation =
    hasBeenCancelled && currentBooking.cancellationDetails.reason !== CANCEL_REASON_KEYS.REVOCATION;

  const hasRevocation =
    hasBeenCancelled && currentBooking.cancellationDetails.reason === CANCEL_REASON_KEYS.REVOCATION;

  const statusName =
    testBooking?.status.name ||
    Store.getters[`booking/${BookingGetters.GET_CURRENT_BOOKING_STATUS}`].name;

  const today = testDay || dayjs();

  if (
    statusName === ENROLMENT_STATUS_STRINGS.GRADUATED ||
    statusName === ENROLMENT_STATUS_STRINGS.ENDED
  ) {
    return endBookingMenuItems;
  }

  const revocationDeadline =
    currentBooking?.status?.revocationDeadline ||
    dayjs(currentBooking.startDate).add(1, 'month').format(DATE_FORMAT);

  if (today.isBefore(revocationDeadline)) {
    if (hasRevocation) {
      // enableButton(END_BOOKING_EVENTS.ON_RECALL_REVOCATION)
      activatedItems.push(END_BOOKING_EVENTS.ON_RECALL_REVOCATION);
    }
  }

  if (
    today.isBefore(endDate) &&
    !currentBooking?.cancellationDetails?.cancellationDate &&
    currentBooking?.cancellationDetails?.reason !== 'revocation'
  ) {
    activatedItems.push(END_BOOKING_EVENTS.ON_REVOCATION);
  }

  if (
    isActive(FEATURES.BOOKING_COMPLETION_CANCELLATION) &&
    statusName !== ENROLMENT_STATUS_STRINGS.SIGNED
  ) {
    if (hasNormalCancellation) {
      activatedItems.push(END_BOOKING_EVENTS.ON_RECALL_CANCELLATION);
    } else if (today.isAfter(revocationDeadline) && today.isBefore(endDate)) {
      activatedItems.push(END_BOOKING_EVENTS.ON_CANCELLATION);
    }
  }

  // TODO (Tasos): That should be activated back only when we support HZB cancellation reason
  // if (isActive(FEATURES.ADMISSION_PROGRAM)) {
  //   const bookingHasFailedAdmissions = currentBooking?.admissionCourses?.some(
  //     (admission) => admission.status === ADMISSION_PROGRAM_STATUS.FAILED
  //   );

  //   if (isActive(FEATURES.BOOKING_COMPLETION_CANCELLATION) && bookingHasFailedAdmissions) {
  //     activatedItems.push(END_BOOKING_EVENTS.ON_CANCELLATION);
  //   }
  // }

  if (
    isActive(FEATURES.BOOKING_COMPLETION_GRADUATION) &&
    statusName === ENROLMENT_STATUS_STRINGS.STUDYING
  ) {
    if (!isGraduated) {
      activatedItems.push(END_BOOKING_EVENTS.ON_GRADUATION);
    }
  }

  if (activatedItems.length > 0) {
    return endBookingMenuItems.map((item) => {
      if (activatedItems.includes(item.id)) {
        item.disabled = false;
      }

      return item;
    });
  }

  return endBookingMenuItems;
}
