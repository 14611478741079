<template>
  <n-card elevated outlined hoverable data-test="grades-overview__program-card">
    <template #content>
      <div class="flex flex-row justify-between p-2 text-blue-600" @click="navigate">
        <div>
          <p class="font-bold" data-test="grades-overview__program-card--name">
            {{ programCode }}
          </p>
          <admission-chip
            v-if="isAdmissionProgram"
            data-test="grades-overview__program-card--admission"
          />
          <study-progress-status :status="studyProgressStatus" />
          <div v-if="studyProgress !== null" class="-ml-0.5">
            <OwnershipProgramCardLabel
              v-if="studyProgress.ownership.error !== null"
              ownership-label="Cannot determine ownership status"
            />
            <OwnershipProgramCardLabel
              v-else-if="studyProgress.ownership.isOwnedByEPOS"
              ownership-label="Managed in EPOS"
            />
            <OwnershipProgramCardLabel v-else ownership-label="Managed in CARE" />
          </div>
        </div>
      </div>
    </template>
  </n-card>
</template>

<script>
import { NCard } from '@careerpartner/nitro';
import StudyProgressStatus from '@/components/common/StudyProgressStatus.vue';
import { OVERVIEW } from '@/common/constants/router';
import OwnershipProgramCardLabel from '@/components/overview/ownership/OwnershipProgramCardLabel.vue';
import AdmissionChip from '@/components/common/AdmissionChip.vue';
import { StudyProgressUtils } from '@/common/utils/studyProgress';

export default {
  name: 'ProgramCard',
  components: {
    StudyProgressStatus,
    NCard,
    OwnershipProgramCardLabel,
    AdmissionChip,
  },
  props: {
    studyProgress: {
      type: Object,
      required: true,
    },
  },
  computed: {
    programCode() {
      return `${this.studyProgress.program?.programCode} ${this.studyProgress.curriculumVersion.curriculumVersionCode}`;
    },
    studyProgressStatus() {
      return this.studyProgress?.status;
    },
    isAdmissionProgram() {
      return StudyProgressUtils.isAdmissionProgram(this.studyProgress);
    },
  },
  methods: {
    async navigate() {
      await this.$router.push({
        name: OVERVIEW,
        params: {
          bookingId: this.studyProgress.id,
        },
      });
    },
  },
};
</script>
