<template>
  <process-modal-form
    :id="id"
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitCancellation"
  >
    <template #form-content>
      <!-- Reasons -->
      <div>
        <validation-provider
          v-slot="{ errors }"
          name="Grund der Beendigung"
          rules="required"
          immediate
        >
          <e-multiselect
            id="cancellation-reason"
            v-model="cancelReason"
            searchable
            :options="availableReasons"
            option-label="name"
            track-by="key"
            label="Grund der Beendigung"
            :errors="errors"
            required
            mapped
            disabled
          />
        </validation-provider>
      </div>

      <!-- Dates -->
      <div v-show="cancelReason" class="flex space-x-4">
        <validation-provider
          v-slot="{ errors }"
          name="Datum der Kündigung"
          :rules="{
            required: true,
            date_format: true,
          }"
          slim
          immediate
        >
          <e-text-field
            id="booking-cancellation-cancellationDate"
            v-model="cancellationDate"
            type="date"
            label="Datum der Kündigung"
            wide
            :errors="errors"
            required
          />
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="Datum Studienende"
          :rules="{
            required: true,
            date_format: true,
          }"
          slim
          immediate
        >
          <e-text-field
            id="booking-cancellation-contractEnd"
            v-model="contractEndDate"
            type="date"
            label="Datum Studienende"
            wide
            :errors="errors"
            required
            :disabled="contractEndDateDisabled"
          />
        </validation-provider>
      </div>
      <div v-show="cancelReason" style="width: calc(50% - 8px)">
        <validation-provider
          v-if="isTrialPeriodReason"
          v-slot="{ errors }"
          name="Erreichte ECTS Anzahl"
          :rules="{
            required: true,
            numeric: true,
            min_value: {
              value: trialPeriodEcts,
              min: 0,
            },
            max_value: {
              value: trialPeriodEcts,
              max: 15,
            },
          }"
          slim
          immediate
        >
          <e-text-field
            id="booking-trial-period-ects"
            v-model="trialPeriodEcts"
            label="Erreichte ECTS Anzahl"
            wide
            :errors="errors"
            required
            append-inline="/15"
            type="number"
          />
        </validation-provider>
      </div>
    </template>
    <template #submit-button-label> Kündigung durchführen</template>
  </process-modal-form>
</template>

<script>
import { EMultiselect, ETextField } from '@careerpartner/nitro';
import { ValidationProvider, extend } from 'vee-validate';
import { CANCEL_REASON_KEYS } from '@/common/selectLists/cancellationReasons';
import { API_ENDPOINTS, DATE_FORMAT, ADMISSION_PROGRAM_STATUS } from '@/common/constants';
import { required, max } from 'vee-validate/dist/rules';
import dayjs from 'dayjs';
import { Actions as AppActions } from '@/store/modules/app/types';
import provideCancelReasons from '@/utils/provideCancelReasons';
import provideCancelDates from '@/utils/provideCancelDates';
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import '@/common/validationExtends';

extend('required', {
  ...required,
  message: 'Dieses Feld muss ausgefüllt sein.',
});

extend('max', {
  ...max,
  message: `Maximal {length} Zeichen erlaubt.`,
});

export default {
  name: 'CancellationModal',
  components: {
    EMultiselect,
    ETextField,
    ValidationProvider,
    ProcessModalForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  emits: ['cancellation-successful', 'input'],
  data() {
    return {
      error: '',
      cancelReason: '',
      cancelDescription: '',
      cancellationDate: '',
      contractEndAllowedDates: null,
      contractEndDate: '',
      waitingForResponse: false,
      trialPeriodEcts: '0',
      cancelDates: {
        cancelDate: {
          suggestion: '',
          rangeStart: '',
          rangeEnd: '',
        },
        newStudyEndDate: {
          suggestion: '',
          rangeStart: '',
          rangeEnd: '',
        },
      },
    };
  },
  computed: {
    isTrialPeriodReason() {
      return this.cancelReason === CANCEL_REASON_KEYS.FAILED_TRIAL;
    },
    availableReasons() {
      return provideCancelReasons(
        dayjs(this.booking.startDate),
        dayjs(this.booking.status.revocationDeadline),
        dayjs(this.booking.endDate),
        dayjs(),
        this.booking.status.name,
        this.booking.trialPeriod?.passed === null,
        this.bookingHasFailedAdmissions
      );
    },
    contractEndDateDisabled() {
      return [
        CANCEL_REASON_KEYS.HZB,
        CANCEL_REASON_KEYS.DOCUMENTS_NOT_SUBMITTED,
        CANCEL_REASON_KEYS.FEES,
        CANCEL_REASON_KEYS.FAILED_EXAM,
        CANCEL_REASON_KEYS.FAILED_TRIAL,
        CANCEL_REASON_KEYS.HEALTH_INSURANCE,
        CANCEL_REASON_KEYS.ADMISSION_PROGRAM,
        CANCEL_REASON_KEYS.REGULAR,
      ].includes(this.cancelReason);
    },
    bookingHasFailedAdmissions() {
      return this.booking.admissionCourses.some(
        (admission) => admission.status === ADMISSION_PROGRAM_STATUS.FAILED
      );
    },
  },
  watch: {
    async cancelReason(val) {
      this.cancelReason = val;
      this.cancelDescription = '';

      await this.updateCancelDates();
      this.setCancellationDate();
    },
    cancellationDate: {
      async handler(date) {
        if (!date) {
          return;
        }

        await this.updateCancelDates();
        this.setContractEndDate();

        // Contract End date is always the cancel date for "failed trial " cancellations
        if (
          [CANCEL_REASON_KEYS.FAILED_TRIAL, CANCEL_REASON_KEYS.ADMISSION_PROGRAM].includes(
            this.cancelReason
          )
        ) {
          this.contractEndDate = date;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.resetReasons();
    this.resetCancellationDate();
    this.resetStudyEndDate();
    this.resetError();
  },
  methods: {
    resetReasons() {
      // For now cancelReason is always "regular"
      // Once we validate other reasons that should be removed
      this.cancelReason = CANCEL_REASON_KEYS.REGULAR;
      this.cancelDescription = '';
    },
    resetCancellationDate() {
      this.cancellationDate = '';
    },
    resetStudyEndDate() {
      this.contractEndDate = '';
    },
    resetError() {
      this.error = '';
    },
    async updateCancelDates() {
      this.waitingForResponse = true;

      try {
        const cancelDates = await provideCancelDates({
          cancelReason: this.cancelReason,
          revocationDeadline: dayjs(this.booking.status.revocationDeadline),
          studyStartDate: dayjs(this.booking.startDate),
          studyEndDate: dayjs(this.booking.endDate),
          cancellationDate: dayjs(this.cancellationDate || dayjs().format('YYYY-MM-DD')),
          earliestTrialPeriodEndDate: dayjs(this.booking.trialPeriod?.earliestEndDate),
          latestTrialPeriodEndDate: dayjs(this.booking.trialPeriod?.latestEndDate),
          bookingId: this.$route.params.bookingId,
        });

        this.cancelDates = cancelDates;
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
    async submitCancellation() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.CANCELLATION.replace(
        ':bookingId',
        this.$route.params.bookingId
      );

      const payload = {
        cancellationDate: dayjs(this.cancellationDate).format(DATE_FORMAT),
        contractEnd: dayjs(this.contractEndDate).format(DATE_FORMAT),
        reason: this.cancelReason,
        description: this.cancelDescription === '' ? null : this.cancelDescription,
        trialPeriodEcts: this.isTrialPeriodReason ? Number(this.trialPeriodEcts) : null,
      };

      // TODO: check if getting the last exam works in the form (for validation)
      try {
        await ApiServiceWrapper.post(requestUrl, payload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Buchung erfolgreich beendet.',
        });

        this.$emit('cancellation-successful');
        this.$emit('input', false);
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
    setCancellationDate() {
      this.cancellationDate = dayjs();
      this.contractEndDate = '';
      this.contractEndAllowedDates = null;

      const { cancelDate } = this.cancelDates;

      this.cancellationDate = cancelDate.suggestion;
    },
    setContractEndDate() {
      const { newStudyEndDate } = this.cancelDates;

      this.contractEndDate = newStudyEndDate.suggestion;
    },
  },
};
</script>
