var render = function render(){var _vm=this,_c=_vm._self._c;return _c('process-modal-form',{attrs:{"id":_vm.id,"invalid":_vm.invalid,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitCancellation},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('div',[_c('validation-provider',{attrs:{"name":"Grund der Beendigung","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-multiselect',{attrs:{"id":"cancellation-reason","searchable":"","options":_vm.availableReasons,"option-label":"name","track-by":"key","label":"Grund der Beendigung","errors":errors,"required":"","mapped":"","disabled":""},model:{value:(_vm.cancelReason),callback:function ($$v) {_vm.cancelReason=$$v},expression:"cancelReason"}})]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cancelReason),expression:"cancelReason"}],staticClass:"flex space-x-4"},[_c('validation-provider',{attrs:{"name":"Datum der Kündigung","rules":{
          required: true,
          date_format: true,
        },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-text-field',{attrs:{"id":"booking-cancellation-cancellationDate","type":"date","label":"Datum der Kündigung","wide":"","errors":errors,"required":""},model:{value:(_vm.cancellationDate),callback:function ($$v) {_vm.cancellationDate=$$v},expression:"cancellationDate"}})]}}])}),_c('validation-provider',{attrs:{"name":"Datum Studienende","rules":{
          required: true,
          date_format: true,
        },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-text-field',{attrs:{"id":"booking-cancellation-contractEnd","type":"date","label":"Datum Studienende","wide":"","errors":errors,"required":"","disabled":_vm.contractEndDateDisabled},model:{value:(_vm.contractEndDate),callback:function ($$v) {_vm.contractEndDate=$$v},expression:"contractEndDate"}})]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cancelReason),expression:"cancelReason"}],staticStyle:{"width":"calc(50% - 8px)"}},[(_vm.isTrialPeriodReason)?_c('validation-provider',{attrs:{"name":"Erreichte ECTS Anzahl","rules":{
          required: true,
          numeric: true,
          min_value: {
            value: _vm.trialPeriodEcts,
            min: 0,
          },
          max_value: {
            value: _vm.trialPeriodEcts,
            max: 15,
          },
        },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-text-field',{attrs:{"id":"booking-trial-period-ects","label":"Erreichte ECTS Anzahl","wide":"","errors":errors,"required":"","append-inline":"/15","type":"number"},model:{value:(_vm.trialPeriodEcts),callback:function ($$v) {_vm.trialPeriodEcts=$$v},expression:"trialPeriodEcts"}})]}}],null,false,358269465)}):_vm._e()],1)]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v(" Kündigung durchführen")]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }