import {
  EnrolmentExceptionCodes,
  GeneralExceptionCodes,
  RecognitionExceptionCodes,
  UnenrolmentExceptionCodes,
} from './api-exceptions';

export const API_ERROR_MAP = {
  [UnenrolmentExceptionCodes.HAS_ENROLED_COURSES]:
    'Zu dieser Modulbuchung existieren noch Kursbuchungen.',
  [UnenrolmentExceptionCodes.GRADE_RESULT_EXISTS]:
    'Ausbuchung nicht möglich, da der Kursfortschritt bereits gestartet wurde.',
  [EnrolmentExceptionCodes.COURSE_ALREADY_ENROLED]: {
    default: 'Dieser Kurs',
    message: '<Name> ist bereits gebucht.',
  },
  [EnrolmentExceptionCodes.MODULE_ALREADY_ENROLED]: {
    default: 'Dieses Modul',
    message: '<Name> ist bereits gebucht.',
  },
  [EnrolmentExceptionCodes.NO_SUFFICIENT_ECTS_BUDGET]: 'Das ECTS Budget reicht nicht aus.',
  [EnrolmentExceptionCodes.NOT_ENROLABLE]: 'Dieser Studienfortschritt ist nicht aktiv.',
  [EnrolmentExceptionCodes.CONTEXT_COURSE_NOT_PART_OF_PRODUCT_OFFER]:
    'Das Kursangebot und die Kursversion stimmen nicht überein.',
  [EnrolmentExceptionCodes.CONTEXT_MODULE_NOT_PART_OF_PRODUCT_OFFER]:
    'Das Modulangebot und die Modulversion stimmen nicht überein.',

  [RecognitionExceptionCodes.COURSE_ALREADY_RECOGNIZED]:
    'Für diesen Kurs existiert bereits eine Anerkennung.',
  [RecognitionExceptionCodes.COURSE_ENROLLMENT_EXISTS]:
    'Für diesen Kurs existiert bereits eine Einschreibung',
  [RecognitionExceptionCodes.INVALID_STUDYPROGRESS_STATUS]:
    'Dieser Studienfortschritt ist nicht aktiv',

  [GeneralExceptionCodes.NOT_FOUND]: {
    message: '<Name> konnte nicht gefunden werden.',
  },
  [GeneralExceptionCodes.UNKNOWN_EXCEPTION]: 'Etwas ist schiefgelaufen. Bitte versuche es erneut.',
  // For now the user cannot really enter any false data, thats why currently any validation exception would be caused by us
  [GeneralExceptionCodes.VALIDATION_EXCEPTION]: 'Es gibt ein technisches Problem.',
  [GeneralExceptionCodes.ARGUMENT_INVALID]: 'Es gibt ein technisches Problem.',
  [GeneralExceptionCodes.STUDY_PROGRESS_INCOMPLETE]:
    'Der Studienfortschritt wird zurzeit erstellt. Bitte versuchen Sie es später erneut.',
};

export const NETWORK_ERROR_MAP = {
  ECONNABORTED: 'Es scheint, die Anfrage hat zu lange gedauert. Bitte versuche es später erneut.',
};

export const GATEWAY_ERROR_MAP = {
  UNAUTHORIZED: 'Du bist nicht berechtigt diese Aktion durchzuführen.',
};

export const VALIDATION_ERROR_MAP = {
  ENROLMENT_VALIDATION_FAILED: 'Facheinbuchung nicht ohne Moduleinbuchung möglich.',
};
