<template>
  <div v-if="!isRecognitionModeActive" class="mt-6" style="min-width: 30rem">
    <n-card sheet outlined rounded class="overflow-visible">
      <template #content>
        <div class="flex justify-between gap-x-4 xl:gap-x-8 p-2 text-blue-600">
          <div data-test="study-progress__status-indicator">
            <p class="font-bold">{{ getCurriculumVersionCode }}</p>
            <admission-chip
              v-if="isAdmissionProgram"
              data-test="grades-overview__stat-bar--admission"
            />
            <study-progress-status :status="status" />
          </div>
          <div data-test="study-progress__status-grade">
            <p class="font-bold">Durchschnitt</p>
            <p class="font-bold">
              <img :src="graduationCapIcon" class="mr-1 inline-block" alt="graduation cap" />
              {{ getAverageGrade }}
            </p>
          </div>
          <div data-test="study-progress__status-ects-budget">
            <p class="font-bold">ECTS-Budget</p>
            <p v-if="ects.revocationPeriodActive" class="font-bold">
              {{ `Begrenzt bis ${ectsCapUntilFormatted}` }}
            </p>
            <p v-else-if="ects.ectsBasedBooking" class="font-bold">Begrenzt</p>
            <p v-else class="font-bold">Unbegrenzt</p>
          </div>
          <div style="min-width: 32rem; max-width: 64rem" data-test="study-progress__status-ects">
            <p class="flex font-bold mb-1.5" data-test="study-progress__status-tooltip">
              <span class="mr-2"
                >{{ ectsDisplay }}
                {{ getEctsAchievedLabel }}
              </span>
              <n-tooltip data-test="study-progress__status-tooltip-icon">
                <template #content>
                  <div
                    class="font-normal text-base flex flex-col items-start"
                    data-test="study-progress__status-tooltip-text"
                  >
                    <p data-test="study-progress__ects-achieved-total">
                      <span class="mr-2 h-4 w-6 inline-block bg-blue-600"></span
                      >{{ ectsTooltipContent.achieved }} {{ getEctsAchievedLabel }}
                    </p>
                    <p data-test="study-progress__ects-booked">
                      <span class="mr-2 h-4 w-6 inline-block bg-stripes"></span
                      >{{ ectsTooltipContent.booked }} {{ getEctsBookedLabel }}
                    </p>
                  </div>
                </template>
                <n-icon icon="mdi-information-outline text-cta leading-6" size="2xl" />
              </n-tooltip>
            </p>
            <study-progress-bar
              :ects-budget="ectsBudget.total"
              :ects-booked="ects.booked"
              :ects-achieved="ects.achievedTotal"
              :ects-total="ects.programEcts"
              :ects-recognized="ects.recognized"
            />
          </div>
        </div>
      </template>
    </n-card>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { NCard, NIcon, NTooltip } from '@careerpartner/nitro';
import StudyProgressBar from '@/components/common/StudyProgressBar.vue';
import StudyProgressStatus from '@/components/common/StudyProgressStatus.vue';
import AdmissionChip from '@/components/common/AdmissionChip.vue';

export default {
  name: 'StatBar',
  components: {
    NIcon,
    NCard,
    NTooltip,
    StudyProgressBar,
    StudyProgressStatus,
    AdmissionChip,
  },
  data: () => ({
    graduationCapIcon:
      'https://res.cloudinary.com/iubh/image/upload/v1667830338/IT%20-%20Epos%20/illustrations/graduation-cap-epos.svg',
  }),
  computed: {
    ...mapGetters({
      getAverageGrade: `studyProgresses/getAverageGrade`,
      getSelectedStudyProgress: `studyProgresses/getSelectedStudyProgress`,
      getCurriculumVersionCode: `studyProgresses/getCurriculumVersionCode`,
      isAdmissionProgram: `studyProgresses/isAdmissionProgram`,
    }),
    ...mapState('overview', ['isRecognitionModeActive']),
    ects() {
      return this.getSelectedStudyProgress?.ects ? this.getSelectedStudyProgress?.ects : {};
    },
    ectsBudget() {
      return this.getSelectedStudyProgress?.ectsBudget
        ? this.getSelectedStudyProgress?.ectsBudget
        : {};
    },
    status() {
      return this.getSelectedStudyProgress?.status;
    },
    ectsCapUntilFormatted() {
      if (this.ects.ectsCapUntil) {
        return new Intl.DateTimeFormat('de-DE').format(new Date(this.ects.ectsCapUntil));
      }
      return '';
    },
    ectsTooltipContent() {
      let achieved = 'N/A';
      let booked = 'N/A';

      if (this.getSelectedStudyProgress?.ects?.achievedTotal >= 0) {
        achieved = this.getSelectedStudyProgress?.ects?.achievedTotal;
      }

      /**
       * The `booked` value from the backend consists all enrolled courses regardless if they are already passed or still in progress.
       * Therefore, we need to minus the `achievedFromExams` to show ects of enrolled courses that are still not yet passed
       */
      if (this.getSelectedStudyProgress?.ects?.booked >= 0) {
        booked =
          this.getSelectedStudyProgress?.ects?.booked -
          this.getSelectedStudyProgress?.ects?.achievedFromExams;
      }

      return { achieved, booked };
    },
    ectsDisplay() {
      const achieved = this.getSelectedStudyProgress?.ects?.achievedTotal ?? '0';
      const total = this.getSelectedStudyProgress?.ects?.programEcts ?? '0';
      return `${achieved}/${total}`;
    },
    getEctsAchievedLabel() {
      return this.isAdmissionProgram ? 'ECTS-Äquivalent absolviert' : 'ECTS-Punkte absolviert';
    },
    getEctsBookedLabel() {
      return this.isAdmissionProgram ? 'ECTS-Äquivalent gebucht' : 'ECTS-Punkte gebucht';
    },
  },
};
</script>
<style scoped>
.bg-stripes {
  background: repeating-linear-gradient(
    -45deg,
    rgb(45, 54, 85),
    rgb(45, 54, 85) 3px,
    rgb(192, 201, 232) 3px,
    rgb(192, 201, 232) 6px
  );
}
</style>
