<template>
  <toggle>
    <template #default="{ active, toggle }">
      <grades-table-curriculum-term-head
        data-test="grades-overview__curriculum-term"
        :name="getCurriculumTermName"
        :ects="curriculumTerm.ects"
        :active="active"
        @click="toggle"
      />
      <!-- MODULES -->
      <grades-table-curriculum-module
        v-for="curriculumModule of curriculumTerm.curriculumModules"
        :key="`curriculum-module-${curriculumModule.id}`"
        :active="active"
        :curriculum-module="curriculumModule"
        :hide-ects="isAdmissionProgram"
      />
      <!-- CURRICULUM STRUCTURE ELEMENTS -->
      <grades-table-curriculum-structure-element
        v-for="curriculumStructureElement of curriculumTerm.curriculumStructureElements"
        :key="`curriculum-structure-element-${curriculumStructureElement.id}`"
        :active="active"
        :curriculum-structure-element="curriculumStructureElement"
      />
    </template>
  </toggle>
</template>
<script>
import GradesTableCurriculumModule from '@/components/overview/GradesTableCurriculumModule.vue';
import GradesTableCurriculumStructureElement from '@/components/overview/GradesTableCurriculumStructureElement.vue';
import GradesTableCurriculumTermHead from '@/components/overview/GradesTableCurriculumTermHead.vue';
import Toggle from '@/components/overview/Toggle.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'GradesTableCurriculumTerm',
  components: {
    Toggle,
    GradesTableCurriculumTermHead,
    GradesTableCurriculumModule,
    GradesTableCurriculumStructureElement,
  },
  props: {
    curriculumTerm: {
      type: Object,
      required: true,
    },
  },
  emits: ['toggle'],
  computed: {
    ...mapGetters({ isAdmissionProgram: `studyProgresses/isAdmissionProgram` }),
    getCurriculumTermName() {
      if (this.isAdmissionProgram && !this.curriculumTerm.term) {
        return 'Zulassungsphase';
      }

      return this.curriculumTerm.identifier ?? 0;
    },
  },
};
</script>
