<script setup>
import { NBanner, NIcon } from '@careerpartner/nitro';
import { IamUser } from '@/common/iam/iamUser';

const { studyProgress, profileAcademyId } = defineProps({
  studyProgress: {
    type: Object,
    required: true,
  },
  profileAcademyId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['resignOwnershipInitiated']);

const hasResignOwnershipPermission = IamUser.hasPermission('ownership', 'd');

let careUserProfileUrl = '#';

if (import.meta.env && import.meta.env.VITE_CARE_USER_PROFILE_URL && profileAcademyId !== '') {
  careUserProfileUrl = `${import.meta.env.VITE_CARE_USER_PROFILE_URL}${profileAcademyId}`;
}
</script>

<template>
  <div v-if="studyProgress !== null && studyProgress.ownership !== null" class="mt-3">
    <NBanner
      v-if="studyProgress.ownership.error !== null"
      variant="error"
      icon="bug-outline"
      data-test="ownership-banner-error"
    >
      <template #header>Der Ownershipstatus konnte nicht bestimmt werden.</template>
      <template #message>
        <div class="my-3">
          Der Ownershipstatus für diesen Studienfortschritt kann momentan aufgrund eines
          unerwarteten Fehlers nicht bestimmt werden.
        </div>
        <div class="my-3">Bitte laden Sie die Seite neu oder versuchen Sie es später erneut.</div>
      </template>
    </NBanner>
    <NBanner
      v-else-if="studyProgress.ownership.isOwnedByEPOS"
      variant="info"
      icon="information-outline"
      data-test="ownership-banner-epos"
    >
      <template #header>Diese Information wird in EPOS verwaltet.</template>
      <template #message>
        <div v-if="hasResignOwnershipPermission">
          <div data-test="ownership-text-with-permission" class="my-3">
            Die Änderungen werden in Kürze in CARE angezeigt. Einige EPOS Funktionen stehen
            möglicherweise nicht zur Verfügung.
          </div>
          <div
            class="my-3 align-baseline font-bold flex items-center"
            data-test="resign-ownership-button"
          >
            <n-icon icon="account-arrow-right" class="mr-1" /><button
              @click="emits('resignOwnershipInitiated')"
            >
              VERWALTUNG UMSCHALTEN
            </button>
          </div>
        </div>
        <div v-else data-test="ownership-text-without-permission" class="my-3">
          Die Änderungen werden in Kürze in CARE angezeigt. Fehlen dir in EPOS Funktionen, schalte
          die Verwaltung wieder auf CARE um.
        </div>
      </template>
    </NBanner>
    <NBanner v-else variant="warning" icon="alert-outline" data-test="ownership-banner-care">
      <template #header>Diese Information wird in CARE verwaltet.</template>
      <template #message>
        <div class="my-3">
          Änderungen werden in CARE möglicherweise nicht korrekt wiedergegeben. Für eine sichere
          Bearbeitung solltest du alle Änderungen direkt in CARE vornehmen.
        </div>
        <div class="my-3 align-baseline font-bold flex items-center">
          <n-icon icon="mdi-pencil-outline" class="mr-1" /><a :href="careUserProfileUrl"
            >IN CARE BEARBEITEN</a
          >
        </div>
      </template>
    </NBanner>
  </div>
</template>
